@media screen and (min-width: 600px) {
  .tab {
    padding: 20px;
  }
}

.captionerInterface {
  height: calc(100vh - (2.5rem)) !important;
}

.tab {
  overflow-x: hidden;
  overflow-y: hidden;
  padding-bottom: 10px;
}
