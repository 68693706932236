.pop-out-button{
    padding: 0.5rem !important;
    justify-content: space-around !important;
    height: fit-content !important;
}

.open-chat-icon{
    cursor: pointer;
}

.open-chat-icon:hover{
    color: #7f85f5;
}
