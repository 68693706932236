.premeeting-button {
  width: fit-content;
}

.review-container-text {
  opacity: 0.5;
}

.copy-link-button {
  padding: 0.5rem 1.5rem !important;
  justify-content: space-around !important;
  height: fit-content !important;
}
