.languageDropdown{
    width: 100%;
}

.languageLoader{
    position: absolute;
    padding: 4px;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
}